import React from "react";
import {
  ResponsiveContainer,
  XAxis,
  Area,
  AreaChart,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { formatChartDate, roundup, toLocaleString } from "../../utls/helpers";
import ArtistSummaryByCountry from "./ArtisteSummaryByCountry";
import ArtistSummaryByPlaylist from "./ArtisteSummaryByPlaylist";

const ArtistSummary = ({ report }) => {
  return (
    <div>
      <div className="artist-summary">
        <div className="top-bar">
          <div className="title">All Playlists' Campaigns Summary</div>
          <div className="platform">{report.artist}</div>
          <div>
            {report.startDate} - {report.endDate}
          </div>
        </div>

        <React.Fragment>
          <Summary summary={report.summary} />
          {report.summary.countries.length > 0 && (
            <div style={{ maxWidth: "700px" }}>
              {/* <SummaryByCountry countries={summary.metrics.countries} /> */}
            </div>
          )}
        </React.Fragment>

        <div className="double-charts">
          <div className="chart-item">
            <div className="title">Daily spend last 27 days</div>
            <ResponsiveContainer width="100%" height={200}>
              <AreaChart data={report.summary.dailySpendPerFollower}>
                <defs>
                  <linearGradient id="colorSpend" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3f51b5" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#3f51b5" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="date"
                  tickLine={false}
                  tickFormatter={(str) => formatChartDate(str)}
                />
                <YAxis
                  domain={["dataMin - 1", "auto"]}
                  dataKey="spend"
                  tickCount={10}
                />
                <Area
                  type="monotone"
                  dataKey="spend"
                  stroke="#3f51b5"
                  fillOpacity={1}
                  fill="url(#colorSpend)"
                />
                <Tooltip />
                <CartesianGrid opacity={0.5} vertical={false} />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div className="chart-item">
            <div className="title">Daily spend per follower last 27 days</div>
            <ResponsiveContainer width="100%" height={200}>
              <AreaChart data={report.summary.dailySpendPerFollower}>
                <defs>
                  <linearGradient id="colorSpend" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3f51b5" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#3f51b5" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="date"
                  tickLine={false}
                  tickFormatter={(str) => formatChartDate(str)}
                />
                <YAxis
                  domain={["dataMin - 1", "auto"]}
                  dataKey="spendPerFollower"
                  tickCount={20}
                />
                <Area
                  type="monotone"
                  dataKey="spendPerFollower"
                  stroke="#3f51b5"
                  fillOpacity={1}
                  fill="url(#colorSpend)"
                />
                <Tooltip />
                <CartesianGrid opacity={0.5} vertical={false} />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <ArtistSummaryByCountry countries={report.summary.countries} />
      </div>
      <ArtistSummaryByPlaylist data={report.artistCampaignsData} />
    </div>
  );
};

export default ArtistSummary;

const Summary = ({ summary, loading }) => {
  const {
    spend,
    clicks,
    impressions,
    cpc,
    followers,
    totalCampaings,
    totalPlaylists,
  } = summary;

  return (
    <div className={!loading ? "summary" : "summary loading"}>
      <div className="item">
        <div className="heading">Spend</div>
        <div className="value">€{toLocaleString(roundup(spend))}</div>
      </div>
      <div className="item">
        <div className="heading">Gained Followers</div>
        <div className="value">{toLocaleString(followers)}</div>
      </div>
      <div className="item">
        <div className="heading">Spend Per Follower</div>
        <div className="value">
          €{!spend || !followers ? "0.00" : roundup(spend / followers)}
        </div>
      </div>
      <div className="item">
        <div className="heading">Impressions</div>
        <div className="value">{toLocaleString(impressions)}</div>
      </div>
      <div className="item">
        <div className="heading">Clicks</div>
        <div className="value">{toLocaleString(clicks)}</div>
      </div>
      <div className="item">
        <div className="heading">Cost Per Click</div>
        <div className="value">€{roundup(cpc)}</div>
      </div>
      <div className="item">
        <div className="heading">Click Per Follower</div>
        <div className="value">
          {!clicks || !followers ? "0.00" : roundup(clicks / followers)}
        </div>
      </div>
      <div className="item">
        <div className="heading">Total Playlists</div>
        <div className="value">{totalPlaylists}</div>
      </div>
      <div className="item">
        <div className="heading">Total Campaigns</div>
        <div className="value">{totalCampaings}</div>
      </div>
    </div>
  );
};
