import React from "react";
import {
  ResponsiveContainer,
  XAxis,
  Area,
  AreaChart,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { formatChartDate, roundup, toLocaleString } from "../../utls/helpers";
import SummaryByCountry from "../SummaryByCountry";

const ArtistSummaryByPlaylist = ({ data }) => {
  return (
    <div style={{ marginTop: "1rem" }}>
      <div>
        {data
          .sort((a, b) => b.metrics.spend - a.metrics.spend)
          .map((playlist) => (
            <div
              style={{ marginTop: "2rem" }}
              key={playlist.playlist}
              className="artist-summary"
            >
              <div className="top-bar">
                <div className="title">{playlist.playlist}</div>
              </div>
              <React.Fragment>
                <Summary
                  summary={{
                    ...playlist.metrics,
                    followers: playlist.followers,
                  }}
                />
              </React.Fragment>
              <div className="double-charts">
                <div className="chart-item">
                  <div className="title">Daily spend last 27 days</div>
                  <ResponsiveContainer width="100%" height={200}>
                    <AreaChart data={playlist.dailySpendPerFollower}>
                      <defs>
                        <linearGradient
                          id="colorSpend"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#3f51b5"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#3f51b5"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis
                        dataKey="date"
                        tickLine={false}
                        tickFormatter={(str) => formatChartDate(str)}
                      />
                      <YAxis
                        domain={["dataMin - 1", "auto"]}
                        dataKey="spend"
                        tickCount={10}
                      />
                      <Area
                        type="monotone"
                        dataKey="spend"
                        stroke="#3f51b5"
                        fillOpacity={1}
                        fill="url(#colorSpend)"
                      />
                      <Tooltip />
                      <CartesianGrid opacity={0.5} vertical={false} />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div className="chart-item">
                  <div className="title">
                    Daily spend per follower last 27 days
                  </div>
                  <ResponsiveContainer width="100%" height={200}>
                    <AreaChart data={playlist.dailySpendPerFollower}>
                      <defs>
                        <linearGradient
                          id="colorSpend"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#3f51b5"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#3f51b5"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis
                        dataKey="date"
                        tickLine={false}
                        tickFormatter={(str) => formatChartDate(str)}
                      />
                      <YAxis
                        domain={["dataMin - 1", "auto"]}
                        dataKey="spendPerFollower"
                        tickCount={20}
                      />
                      <Area
                        type="monotone"
                        dataKey="spendPerFollower"
                        stroke="#3f51b5"
                        fillOpacity={1}
                        fill="url(#colorSpend)"
                      />
                      <Tooltip />
                      <CartesianGrid opacity={0.5} vertical={false} />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <SummaryByCountry countries={playlist.metrics.countries} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ArtistSummaryByPlaylist;

const Summary = ({ summary, loading }) => {
  const { spend, clicks, impressions, cpc, followers } = summary;

  return (
    <div className={!loading ? "summary" : "summary loading"}>
      <div className="item">
        <div className="heading">Spend</div>
        <div className="value">€{toLocaleString(roundup(spend))}</div>
      </div>
      <div className="item">
        <div className="heading">Gained Followers</div>
        <div className="value">{toLocaleString(followers)}</div>
      </div>
      <div className="item">
        <div className="heading">Spend Per Follower</div>
        <div className="value">
          €{!spend || !followers ? "0.00" : roundup(spend / followers)}
        </div>
      </div>
      <div className="item">
        <div className="heading">Impressions</div>
        <div className="value">{toLocaleString(impressions)}</div>
      </div>
      <div className="item">
        <div className="heading">Clicks</div>
        <div className="value">{toLocaleString(clicks)}</div>
      </div>
      <div className="item">
        <div className="heading">Cost Per Click</div>
        <div className="value">€{roundup(cpc)}</div>
      </div>
      <div className="item">
        <div className="heading">Click Per Follower</div>
        <div className="value">
          {!clicks || !followers ? "0.00" : roundup(clicks / followers)}
        </div>
      </div>
    </div>
  );
};
