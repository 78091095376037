import React, { useCallback, useEffect, useState } from "react";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Alert from "@material-ui/lab/Alert";
import { format } from "date-fns";
import SpendPerDayPerFollowersChart from "./SpendPerDayPerFollowersChart";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import SummaryByCountry from "./SummaryByCountry";
import httpClient from "../services.httpClient";

const roundup = (number) => {
  return number ? Number(number).toFixed(2) : 0.0;
};

const toLocaleString = (number) => {
  return !number || isNaN(number) ? 0.0 : Number(number).toLocaleString();
};

const AVERAGE_CPM = 3.81;

const CampaignSummary = ({ selectedPlaylist, dates, setDates }) => {
  const [summary, setSummary] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [focusedInput, setFocusedInput] = useState(null);
  const [reactDates, setReactDates] = useState({
    startDate: moment(new Date()),
    endDate: moment(new Date()),
  });
  const handleReactDatesChange = ({ startDate, endDate }) => {
    setReactDates((prev) => {
      if (prev.startDate && prev.endDate) return { startDate, endDate: null };
      else if (!endDate) {
        if (focusedInput === "startDate") {
          return { startDate, endDate: null };
        }
        setDates([startDate.toDate(), prev.startDate.toDate()]);
        setFocusedInput(null);
        return { startDate, endDate: prev.startDate };
      } else if (endDate) {
        setDates([startDate.toDate(), endDate.toDate()]);
        return { startDate, endDate };
      }
    });
  };

  const isOutsideRange = (day) => {
    return day.isAfter(moment(new Date()).endOf("day"));
  };

  const fetchSummary = useCallback(() => {
    const { spotifyId } = selectedPlaylist;
    if (!dates) return;
    setLoading(true);
    httpClient
      .get(`/playlists/${spotifyId}/campaigns-report`, {
        params: {
          startDate: format(dates[0], "yyyy-MM-dd"),
          endDate: format(dates[1], "yyyy-MM-dd"),
        },
      })
      .then((res) => {
        setSummary(res.data);
        setErrorMessage("");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErrorMessage(
          "At least one campaing Id is invalid, Please edit playlist's campaigns and try again"
        );
      });
  }, [dates, selectedPlaylist]);

  useEffect(() => {
    if (selectedPlaylist) {
      fetchSummary();
    } else {
      setSummary("");
      setErrorMessage("Please add campaings to view data");
    }
  }, [selectedPlaylist, fetchSummary]);

  return (
    <div>
      <div className="campaign-summary">
        <div className="top-bar">
          <div className="title">Ad Summary</div>
          {selectedPlaylist && (
            <React.Fragment>
              <div className="platform">All Campaigns</div>
              <div className="input">
                <DateRangePicker
                  startDate={reactDates.startDate}
                  startDateId="your_unique_start_date_id"
                  endDate={reactDates.endDate}
                  endDateId="your_unique_end_date_id"
                  onDatesChange={handleReactDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={setFocusedInput}
                  isOutsideRange={isOutsideRange}
                  numberOfMonths={1}
                  minimumNights={0}
                  openDirection="up"
                  displayFormat="DD-MM-YYYY"
                />
              </div>
            </React.Fragment>
          )}
        </div>
        {errorMessage && <Alert severity="info">{errorMessage}</Alert>}
        {summary && summary.metrics && (
          <React.Fragment>
            <Summary loading={loading} summary={summary} />
            {summary.metrics.countries?.length > 0 && (
              <div style={{ maxWidth: "700px" }}>
                <SummaryByCountry countries={summary.metrics.countries} />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <SpendPerDayPerFollowersChart selectedPlaylist={selectedPlaylist} />
    </div>
  );
};

export default CampaignSummary;

const Summary = ({ summary, loading }) => {
  const { spend, clicks, impressions } = summary.metrics;
  const { followers, playlistStreams } = summary;
  return (
    <div className={!loading ? "summary" : "summary loading"}>
      <div className="item">
        <div className="heading">Spend</div>
        <div className="value">€{toLocaleString(roundup(spend))}</div>
      </div>
      <div className="item">
        <div className="heading">Gained Followers</div>
        <div className="value">{toLocaleString(followers)}</div>
      </div>
      <div className="item">
        <div className="heading">Spend Per Follower</div>
        <div className="value">
          €{!spend || !followers ? "0.00" : roundup(spend / followers)}
        </div>
      </div>
      <div className="item">
        <div className="heading">Impressions</div>
        <div className="value">{toLocaleString(impressions)}</div>
      </div>
      <div className="item">
        <div className="heading">Clicks</div>
        <div className="value">{toLocaleString(clicks)}</div>
      </div>
      <div className="item">
        <div className="heading">Cost Per Click</div>
        <div className="value">€{roundup(spend / clicks)}</div>
      </div>
      <div className="item">
        <div className="heading">Click Per Follower</div>
        <div className="value">
          {!clicks || !followers ? "0.00" : roundup(clicks / followers)}
        </div>
      </div>
      <div className="item">
        <div className="heading">Playlist Streams</div>
        <div className="value">{toLocaleString(playlistStreams.streams)}</div>
      </div>
      <div className="item">
        <div className="heading">Estimated Revenue</div>
        <div className="value">
          €
          {playlistStreams.streams
            ? toLocaleString((playlistStreams.streams / 1000) * AVERAGE_CPM)
            : 0}
        </div>
      </div>
    </div>
  );
};
