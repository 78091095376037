import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import httpClient from "../services.httpClient";

function DeletePlaylist({ open, setIsOpen, selectedPlaylist }) {
  const [submissionError, setSubmissionError] = useState("");

  useEffect(() => {
    if (open) {
      setSubmissionError("");
    }
  }, [open]);
  const handleDeletePlaylist = () => {
    httpClient
      .delete(`/playlists/${selectedPlaylist.spotifyId}`)
      .then(() => {
        toast.success("Playlist deleted successfully");
        setIsOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data) setSubmissionError(err.response.data.message);
      });
  };

  return (
    <div>
      <Dialog keepMounted open={open}>
        <DialogTitle id="form-dialog-title">Delete Playlist</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete playlist{" "}
            <strong>"{selectedPlaylist.name}"</strong> ?
            <br />
            <br />
            <Alert severity="error">This action is irreversible!</Alert>
          </DialogContentText>
          {submissionError && <Alert severity="error">{submissionError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeletePlaylist} color="primary">
            Delete Playlist
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeletePlaylist;
