import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  XAxis,
  Area,
  AreaChart,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { format } from "date-fns";
import httpClient from "../services.httpClient";

const formatDate = (date) => {
  return format(new Date(date), "do MMM yyyy");
};

const SpendPerDayPerFollowersChart = ({ selectedPlaylist }) => {
  const [dailySpendPerFollower, setDailySpendPerFollower] = useState();

  useEffect(() => {
    if (selectedPlaylist) {
      httpClient
        .get(`/playlists/${selectedPlaylist.spotifyId}/campaigns-report-daily`)
        .then((res) => {
          setDailySpendPerFollower(res.data.dailySpendPerFollower);
        })
        .catch();
    }
  }, [selectedPlaylist]);

  return (
    <div className="double-charts">
      <div className="chart-item">
        {selectedPlaylist && (
          <div className="title">Daily spend last 27 days</div>
        )}
        {dailySpendPerFollower && selectedPlaylist.campaigns.length > 0 && (
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart data={dailySpendPerFollower}>
              <defs>
                <linearGradient id="colorSpend" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#3f51b5" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#3f51b5" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                tickLine={false}
                tickFormatter={(str) => formatDate(str)}
              />
              <YAxis
                domain={["dataMin - 1", "auto"]}
                dataKey="spend"
                tickCount={10}
              />
              <Area
                type="monotone"
                dataKey="spend"
                stroke="#3f51b5"
                fillOpacity={1}
                fill="url(#colorSpend)"
              />
              <Tooltip />
              <CartesianGrid opacity={0.5} vertical={false} />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
      <div className="chart-item">
        {selectedPlaylist && (
          <div className="title">Daily spend per follower last 27 days</div>
        )}
        {dailySpendPerFollower && selectedPlaylist.campaigns.length > 0 && (
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart data={dailySpendPerFollower}>
              <defs>
                <linearGradient id="colorSpend" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#3f51b5" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#3f51b5" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                tickLine={false}
                tickFormatter={(str) => formatDate(str)}
              />
              <YAxis
                domain={["dataMin - 1", "auto"]}
                dataKey="spendPerFollower"
                tickCount={20}
              />
              <Area
                type="monotone"
                dataKey="spendPerFollower"
                stroke="#3f51b5"
                fillOpacity={1}
                fill="url(#colorSpend)"
              />
              <Tooltip />
              <CartesianGrid opacity={0.5} vertical={false} />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default SpendPerDayPerFollowersChart;
