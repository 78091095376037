import React from "react";
import CampaignsCharts from "../components/CampaignsCharts";
import CampaignSummary from "../components/CampaignSummary";
import Chart1 from "../components/Chart1";
import PAGES from "../utls/pages";

const Playlists = ({
  selectedPlaylist,
  dates,
  setSelectedPlaylist,
  setDates,
  page,
}) => {
  return (
    <div style={{ display: page === PAGES.playlists ? "block" : "none" }}>
      <Chart1
        selectedPlaylist={selectedPlaylist}
        dates={dates}
        setSelectedPlaylist={setSelectedPlaylist}
      />
      <CampaignSummary
        dates={dates}
        setDates={setDates}
        selectedPlaylist={selectedPlaylist}
      />
      <CampaignsCharts selectedPlaylist={selectedPlaylist} />
    </div>
  );
};

export default Playlists;
