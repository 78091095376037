import * as React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import countryCodes from "../../utls/countries";
import { roundup } from "../../utls/helpers";

const ArtistSummaryByCountry = ({ countries }) => {
  return (
    <div style={{ marginTop: "1rem" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Campaigns data by country</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell align="right">Spend</TableCell>
                  <TableCell align="right">Impressions</TableCell>
                  <TableCell align="right">Clicks</TableCell>
                  <TableCell align="right">CPC</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(countries)
                  .sort((a, b) => countries[b].spend - countries[a].spend)
                  .map((key) => {
                    const country = countries[key];
                    return (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {countryCodes[key]}
                        </TableCell>
                        <TableCell align="right">
                          {roundup(country.spend)}
                        </TableCell>
                        <TableCell align="right">
                          {country.impressions}
                        </TableCell>
                        <TableCell align="right">{country.clicks}</TableCell>
                        <TableCell align="right">
                          {roundup(country.cpc)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ArtistSummaryByCountry;
