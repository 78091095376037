import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import Button from "@material-ui/core/Button";
import moment from "moment";
import PAGES from "../utls/pages";
import { toast } from "react-toastify";
import ArtistSummary from "../components/report/ArtistSummary";
import httpClient from "../services.httpClient";

const ArtistReport = ({ page }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([new Date(), new Date()]);
  const [report, setReport] = useState();
  const [reactDates, setReactDates] = useState({
    startDate: moment(new Date()),
    endDate: moment(new Date()),
  });

  const handleReactDatesChange = ({ startDate, endDate }) => {
    setReactDates((prev) => {
      if (prev.startDate && prev.endDate) return { startDate, endDate: null };
      else if (!endDate) {
        if (focusedInput === "startDate") {
          return { startDate, endDate: null };
        }
        setDates([startDate.toDate(), prev.startDate.toDate()]);
        setFocusedInput(null);
        return { startDate, endDate: prev.startDate };
      } else if (endDate) {
        setDates([startDate.toDate(), endDate.toDate()]);
        return { startDate, endDate };
      }
    });
  };

  const handleGetReport = () => {
    if (!searchValue) {
      toast.error("Please enter an artist name");
      return;
    }
    if (!reactDates.endDate) {
      toast.error("Please select a date range");
      return;
    }
    setLoading(true);
    const formatedStartDate = moment(dates[0]).format("YYYY-MM-DD");
    const formatedEndDate = moment(dates[1]).format("YYYY-MM-DD");
    httpClient
      .get(
        `/playlists/artist-report?artist=${searchValue}&startDate=${formatedStartDate}&endDate=${formatedEndDate}`
      )
      .then((res) => {
        console.log(res.data);
        setReport({
          ...res.data,
          artist: searchValue,
          startDate: formatedStartDate,
          endDate: formatedEndDate,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };
  return (
    <div
      className="artist-report"
      style={{ display: page === PAGES.artist_report ? "block" : "none" }}
    >
      <div className="top-bar">
        <input
          className="search-input"
          type="text"
          placeholder="Enter artist name"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          disabled={loading}
        />
        <div className="input">
          <DateRangePicker
            startDate={reactDates.startDate}
            startDateId="your_unique_start_date_id"
            endDate={reactDates.endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={handleReactDatesChange}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
            disabled={loading}
            isOutsideRange={(day) =>
              day.isAfter(moment(new Date()).endOf("day"))
            }
            numberOfMonths={1}
            minimumNights={0}
            openDirection="down"
            displayFormat="DD-MM-YYYY"
          />
        </div>
        <Button
          onClick={() => handleGetReport(true)}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Loading..." : "Get Report"}
        </Button>
      </div>
      {report && (
        <React.Fragment>
          <ArtistSummary report={report} />
        </React.Fragment>
      )}
    </div>
  );
};

export default ArtistReport;
