import { format } from "date-fns";

export const formatChartDate = (date) => {
  if (date && date.includes("-")) return format(new Date(date), "do MMM yyyy");
};

export const roundup = (number) => {
  return Number(number).toFixed(2);
};

export const toLocaleString = (number) => {
  return Number(number).toLocaleString();
};
