import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./App.scss";
import Login from "./components/Login";
import Playlists from "./pages/Playlists";
import ArtistReport from "./pages/ArtistReport";
import PAGES from "./utls/pages";
import ArtistEarnings from "./pages/ArtistEarnings";

function App() {
  const [selectedPlaylist, setSelectedPlaylist] = useState("");
  const [dates, setDates] = useState([new Date(), new Date()]);
  const [isLogedIn, setIsLoggedIn] = useState(false);
  const [page, setPage] = useState(PAGES.playlists);

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("udistriAuth");
  };

  useEffect(() => {
    const auth = localStorage.getItem("udistriAuth");
    if (auth && auth === "udistri:Piano!!!") {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        hideProgressBar
        autoClose={3000}
        className="toast-container"
        toastClassName="dark-toast"
      />
      {isLogedIn ? (
        <Header setPage={setPage} logout={logout}>
          <Playlists
            selectedPlaylist={selectedPlaylist}
            dates={dates}
            page={page}
            setSelectedPlaylist={setSelectedPlaylist}
            setDates={setDates}
          />
          <ArtistReport page={page} />
          <ArtistEarnings page={page} />
        </Header>
      ) : (
        <Login setIsLoggedIn={setIsLoggedIn} />
      )}
    </div>
  );
}

export default App;
