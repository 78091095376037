import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import httpClient from "../services.httpClient";

function AddPlaylist({ open, setIsOpen }) {
  const [spotifyId, setSpotifyId] = useState("");
  const [submissionError, setSubmissionError] = useState("");

  useEffect(() => {
    if (open) {
      setSpotifyId("");
      setSubmissionError("");
    }
  }, [open]);
  const handleAddPlaylist = () => {
    if (!spotifyId) {
      setSubmissionError("Please enter Playlist ID");
      return;
    }
    httpClient
      .post(`/playlists/${spotifyId}`)
      .then(() => {
        toast.success("Playlist added successfully");
        setIsOpen(false);
      })
      .catch((err) => {
        if (err.response.data) setSubmissionError(err.response.data.message);
      });
  };

  return (
    <div>
      <Dialog keepMounted open={open}>
        <DialogTitle id="form-dialog-title">Add New Playlist</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the ID of the spotify playlist
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={spotifyId}
            onChange={(e) => setSpotifyId(e.target.value)}
            id="name"
            label="Spotify ID"
            type="text"
            fullWidth
          />
          {submissionError && <Alert severity="error">{submissionError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddPlaylist} color="primary">
            Add Playlist
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddPlaylist;
