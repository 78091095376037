import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import toast from "./toast";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import AddCampaign from "./AddCampaign";
import httpClient from "../services.httpClient";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function EditPlaylist({
  open,
  setIsOpen,
  selectedPlaylist,
  setSelectedPlaylist,
  setIsEdited,
}) {
  const classes = useStyles();
  const [playlist, setPlaylist] = useState({
    name: selectedPlaylist.name,
    isActive: selectedPlaylist.isActive,
    country: selectedPlaylist.country || "",
  });
  const [submissionError, setSubmissionError] = useState("");
  const [updateImage, setUpdateImage] = useState(false);
  const [addCampaignOpen, setAddCampaignOpen] = useState(false);
  const updatePlaylist = () => {
    httpClient
      .put(`/playlists/${selectedPlaylist.spotifyId}`, {
        ...playlist,
        updateImage,
      })
      .then((res) => {
        toast.success("Playlist updated successfully");
        setIsEdited(true);
        setSelectedPlaylist(res.data.playlist);
        setIsOpen(false);
      })
      .catch((err) => {
        if (err.response && err.response.data)
          setSubmissionError(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlaylist((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (open) {
      setPlaylist({
        name: selectedPlaylist.name,
        country: selectedPlaylist.country || "",
        isActive: selectedPlaylist.isActive,
      });
      setUpdateImage(false);
      setIsEdited(false);
    }
  }, [open, selectedPlaylist, setIsEdited]);

  const deleteCampaign = (campaign) => {
    httpClient
      .delete(
        `/playlists/${selectedPlaylist.spotifyId}/campaigns/${campaign.campaign_id}`
      )
      .then((res) => {
        toast.success("Campaign deleted successfully");
        setSelectedPlaylist(res.data.playlist);
      })
      .catch((err) => {
        if (err.response && err.response.data)
          toast.error(err.response.data.message);
      });
  };
  return (
    <div>
      <AddCampaign
        open={addCampaignOpen}
        setIsOpen={setAddCampaignOpen}
        selectedPlaylist={selectedPlaylist}
        setSelectedPlaylist={setSelectedPlaylist}
      />
      <Dialog fullWidth={true} maxWidth={"md"} open={open}>
        <DialogTitle id="form-dialog-title">
          Edit Playlist <br />{" "}
          <p className="font-size-10">{selectedPlaylist.spotifyId}</p>
        </DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            value={playlist.name}
            onChange={handleChange}
            name="name"
            label="Playlist Name"
            type="text"
            fullWidth
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              value={playlist.country}
              name="country"
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={"DE"}>Germany</MenuItem>
              <MenuItem value={"FR"}>France</MenuItem>
              <MenuItem value={"GB"}>United Kingdom</MenuItem>
              <MenuItem value={"ES"}>Spain</MenuItem>
            </Select>
          </FormControl>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={updateImage}
                  onChange={(e) => setUpdateImage(e.target.checked)}
                  color="primary"
                />
              }
              label="Update Playlist Image"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!selectedPlaylist.campaigns.length}
                  checked={playlist.isActive}
                  onChange={(e) =>
                    setPlaylist((prev) => ({
                      ...prev,
                      isActive: e.target.checked,
                    }))
                  }
                  color="primary"
                />
              }
              label="Active Campaigns"
            />
          </div>
          {selectedPlaylist.campaigns.length < 1 && (
            <DialogContentText>
              <div>No Campaings found for this playlist.</div>
            </DialogContentText>
          )}
          {selectedPlaylist.campaigns.length > 0 && (
            <Typography>
              <Typography>Campaigns:</Typography>
              {selectedPlaylist.campaigns.map((campaign, i) => (
                <div key={campaign._id} style={{ fontSize: "1.4rem" }}>
                  {i + 1} ={">"} {campaign.campaign_id} : {campaign.platform} :
                  {campaign.user} :
                  <Button
                    onClick={() => deleteCampaign(campaign)}
                    variant="outlined"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
              ))}
            </Typography>
          )}
          <Button onClick={() => setAddCampaignOpen(true)} variant="outlined">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          {submissionError && <Alert severity="error">{submissionError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={updatePlaylist} color="primary">
            Update Playlist
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditPlaylist;
