import React, { useCallback, useEffect, useState } from "react";
import httpClient from "../services.httpClient";
import DoubleCharts from "./DoubleCharts";

function CampaignsCharts({ selectedPlaylist }) {
  const [campaignsData, setCampaignsData] = useState();
  const [isVisible, setIsVisible] = useState(false);

  const fetchCampaignsData = useCallback(async (id) => {
    const response = await httpClient.get(
      `/playlists/${id}/campaigns-daily-stats`
    );
    setCampaignsData(response.data.data);
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (selectedPlaylist && selectedPlaylist.campaigns.length > 1) {
      fetchCampaignsData(selectedPlaylist.spotifyId);
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [selectedPlaylist, fetchCampaignsData]);
  return (
    <div>
      {isVisible &&
        campaignsData &&
        campaignsData.map((data) => (
          <DoubleCharts
            leftTitle="Daily spend last 27 days"
            rightTitle="Daily cost per click last 27 days"
            key={data.campaign_id}
            data={data}
          />
        ))}
    </div>
  );
}

export default CampaignsCharts;
