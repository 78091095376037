import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import PAGES from "../utls/pages";
import { toast } from "react-toastify";
import {
  ResponsiveContainer,
  XAxis,
  Area,
  AreaChart,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { formatChartDate } from "../utls/helpers";
import countryCodes from "../utls/countries";
import httpClient from "../services.httpClient";

const ArtistEarnings = ({ page }) => {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState();
  const [streamRefreshRun, setStreamRefreshRun] = useState();
  // const yellow = "#e3b007";
  const green = "#05a61d";
  const red = "#c72602";
  const blue = "#0523e6";

  useEffect(() => {
    httpClient
      .get("/artists/kato/streams/refresh-run")
      .then((res) => setStreamRefreshRun(res.data.streamsRefreshRun))
      .catch((err) => toast.error(err.response.data.message));
  }, []);

  const handleGetReport = () => {
    setLoading(true);
    httpClient
      .get(`/artists/kato/earnings`)
      .then((res) => {
        console.log(res.data);
        setReport(res.data.incomeSpends);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };
  useEffect(() => {
    const refreshRunInterval = setInterval(() => {
      httpClient
        .get("/artists/kato/streams/refresh-run")
        .then((res) => setStreamRefreshRun(res.data.streamsRefreshRun))
        .catch((err) => toast.error(err.response.data.message));
    }, 30000);
    return () => clearInterval(refreshRunInterval);
  }, []);

  const handleStreamsRefresh = () => {
    setLoading(true);
    httpClient
      .put(`/artists/kato/streams`)
      .then((res) => {
        setLoading(false);
        setStreamRefreshRun(res.data.streamsRefreshRun);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <div
      className="artist-earnings"
      style={{ display: page === PAGES.earnings ? "block" : "none" }}
    >
      <div className="top-bar">
        <div className="artist">KATO EARNINGS AND SPENDS LAST 28 DAYS</div>
        <Button
          onClick={() => handleGetReport(true)}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Loading..." : "Load Data"}
        </Button>

        <Button
          onClick={handleStreamsRefresh}
          variant="contained"
          disabled={loading}
        >
          Refresh Streams Data
        </Button>
        {streamRefreshRun && streamRefreshRun.status === "running" && (
          <div className="ml-20 w-15">
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={streamRefreshRun.progress}
              />
            </Box>
          </div>
        )}
      </div>
      {report && <React.Fragment></React.Fragment>}
      {loading && <div>Loading data...</div>}
      {!loading &&
        report &&
        Object.keys(report).map((country) => {
          const totalSpend = Math.round(
            report[country].reduce((acc, item) => acc + item.spend, 0)
          );
          const totalIncome = Math.round(
            report[country].reduce((acc, item) => acc + item.income, 0)
          );
          return (
            <div className="mt-20" key={country}>
              <div className="chart-item">
                <div className="title">
                  {countryCodes[country] || "Worldwide"}
                </div>
                <ResponsiveContainer width="100%" height={200}>
                  <AreaChart data={report[country]}>
                    <defs>
                      <linearGradient
                        id="colorSpend"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor="#3f51b5"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#3f51b5"
                          stopOpacity={0}
                        />
                      </linearGradient>
                      <linearGradient
                        id="colorIncome"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor="#0cf562"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#0cf562"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="date"
                      tickLine={false}
                      tickFormatter={(str) => formatChartDate(str)}
                    />
                    <YAxis domain={["dataMin - 1", "auto"]} tickCount={10} />
                    <Area
                      type="monotone"
                      dataKey="spend"
                      stroke="#f73f07"
                      fillOpacity={1}
                      fill="url(#colorSpend)"
                    />
                    <Area
                      type="monotone"
                      dataKey="income"
                      stroke="#05a61d"
                      fillOpacity={1}
                      fill="url(#colorIncome)"
                    />
                    <Tooltip />
                    <CartesianGrid opacity={0.5} vertical={false} />
                  </AreaChart>
                </ResponsiveContainer>
                <div className="font-size-1">
                  <div>
                    Total spend:{" "}
                    <span
                      style={{ color: totalSpend > totalIncome ? red : green }}
                    >
                      {totalSpend}
                    </span>
                  </div>
                  <div>
                    Total income:{" "}
                    <span
                      style={{ color: totalSpend < totalIncome ? blue : green }}
                    >
                      {totalIncome}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ArtistEarnings;
