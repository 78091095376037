import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toast from "./toast";
import Button from "@material-ui/core/Button";
import httpClient from "../services.httpClient";
const users = {
  EPIR: "Epir Thaci",
  JULIAN: "Julian",
  UDISTRI: "uDistri gbr",
};

const tiktokAdvertiserIds = {
  [users.EPIR]: "6926116980374142978",
  [users.JULIAN]: "6925850781983719425",
  [users.UDISTRI]: "6945923808448479234",
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function AddCampaign({
  open,
  setIsOpen,
  selectedPlaylist,
  setSelectedPlaylist,
}) {
  const classes = useStyles();
  const [campaign, setCampaign] = useState({
    campaignId: "",
    platform: "",
    user: "",
  });
  const [submissionError, setSubmissionError] = useState("");

  const addCampaign = () => {
    setSubmissionError("");
    const { campaignId, platform, user } = campaign;
    campaign.advertiserId =
      platform === "tiktok" ? tiktokAdvertiserIds[user] : "";
    if (campaignId === "" || platform === "" || user === "") {
      setSubmissionError("Please fill out all fields");
      return;
    }
    httpClient
      .post(`/playlists/${selectedPlaylist.spotifyId}/campaigns`, {
        ...campaign,
      })
      .then((res) => {
        toast.success("Campaign added successfully");
        setSelectedPlaylist(res.data.playlist);
        setIsOpen(false);
      })
      .catch((err) => {
        if (err.response && err.response.data)
          setSubmissionError(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaign((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (open) {
      setCampaign({
        platform: "",
        user: "",
        advertiserId: "",
        campaignId: "",
      });
      setSubmissionError("");
    }
  }, [open, selectedPlaylist]);
  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
        <DialogTitle id="form-dialog-title">New Campaign</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            value={campaign.campaignId}
            onChange={handleChange}
            name="campaignId"
            label="Campaing Id"
            type="text"
            fullWidth
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">User</InputLabel>
            <Select
              value={campaign.user}
              name="user"
              onChange={handleChange}
              fullWidth
            >
              {Object.values(users).map((adAccount) => (
                <MenuItem key={adAccount} value={adAccount}>
                  {adAccount}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Ad Platform</InputLabel>
            <Select
              value={campaign.platform}
              name="platform"
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={"tiktok"}>Tiktok</MenuItem>
              <MenuItem value={"snapchat"}>Snapchat</MenuItem>
              <MenuItem value={"facebook"}>Facebook</MenuItem>
            </Select>
          </FormControl>
          {submissionError && <Alert severity="error">{submissionError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={addCampaign} color="primary">
            Add campaign
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddCampaign;
