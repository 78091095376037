import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

const Login = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const login = () => {
    if (username === "udistri" && password === "Piano!!!") {
      setIsLoggedIn(true);
      localStorage.setItem("udistriAuth", "udistri:Piano!!!");
    } else {
      setLoginError("invalid username or password");
    }
  };
  return (
    <div className="login">
      <div className="login-wrapper">
        <h3>Login</h3>
        <div className="login-error">
          {loginError && <Alert severity="error">{loginError}</Alert>}
        </div>

        <div className="input-wrapper">
          <label htmlFor="name">username</label>
          <input
            type="text"
            id="name"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="password">password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Button onClick={() => login()} variant="contained" color="primary">
          Log in
        </Button>
      </div>
    </div>
  );
};

export default Login;
