import React, { useCallback, useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import httpClient from "../services.httpClient";

const roundup = (number) => {
  return Number(number).toFixed(2);
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const fetchPlaylistSummary = async (playlist, dates) => {
  const { spotifyId } = playlist;
  if (playlist.campaigns.length < 1)
    return { metrics: { spend: 0 }, followers: 0 };
  try {
    const response = await httpClient.get(
      `/playlists/${spotifyId}/campaigns-report`,
      {
        params: {
          startDate: format(dates[0], "yyyy-MM-dd"),
          endDate: format(dates[1], "yyyy-MM-dd"),
        },
      }
    );
    return response.data;
  } catch (error) {
    return { metrics: { spend: 0 }, followers: 0 };
  }
};

const PlaylistList = ({
  setSelectedPlaylist,
  selectedPlaylist,
  dates,
  isEdited,
}) => {
  const [playlists, setPlaylists] = useState();
  const [filteredPlaylists, setFilteredPlaylists] = useState();
  const [playlistsWithSpends, setPlaylistsWithSpends] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("none");
  const [currentLists, setCurrentList] = useState();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    httpClient
      .get("/playlists")
      .then((res) => {
        setLoading(false);
        const { playlists } = res.data;
        setPlaylists(playlists);
        setFilteredPlaylists(playlists);
        setCurrentList(playlists);
      })
      .catch(() => setLoading(false));
  }, []);

  const handlePlaylistSpends = useCallback(async () => {
    if (!playlists) return;
    const allSpends = {};
    setLoading(true);
    await Promise.all(
      playlists.map(async (playlist) => {
        const data = await fetchPlaylistSummary(playlist, dates);
        if (data.metrics) allSpends[playlist._id] = data;
      })
    );
    const formatedPlaylists = playlists.map((playlist) => {
      let spend = 0;
      let spendPerFollower = 0;
      if (allSpends[playlist._id]) {
        const followers = allSpends[playlist._id].followers;
        spend = allSpends[playlist._id].metrics.spend;
        spendPerFollower = !spend || !followers ? 0 : spend / followers;
      }

      return {
        ...playlist,
        spend,
        spendPerFollower,
      };
    });
    setPlaylistsWithSpends(formatedPlaylists);
    setLoading(false);
  }, [dates, playlists]);

  useEffect(() => {
    if (dates) {
      handlePlaylistSpends();
    }
  }, [dates, handlePlaylistSpends]);

  useEffect(() => {
    if (!playlists) return;
    if (sortBy === "none") {
      setCurrentList(playlists);
    } else if (sortBy === "spend") {
      const sorted = playlistsWithSpends.sort((a, b) => b.spend - a.spend);
      setFilteredPlaylists([...sorted]);
      setCurrentList([...sorted]);
    } else if (sortBy === "spendPerFollower") {
      const sorted = playlistsWithSpends.sort(
        (a, b) => b.spendPerFollower - a.spendPerFollower
      );
      setFilteredPlaylists([...sorted]);
      setCurrentList([...sorted]);
    } else if (sortBy === "isActive") {
      const sorted = playlistsWithSpends.filter(
        (playlist) => playlist.isActive
      );
      setFilteredPlaylists([...sorted]);
      setCurrentList([...sorted]);
    } else if (sortBy === "notActive") {
      const sorted = playlistsWithSpends.filter(
        (playlist) => !playlist.isActive
      );
      setFilteredPlaylists([...sorted]);
      setCurrentList([...sorted]);
    }
  }, [sortBy, playlists, playlistsWithSpends, setCurrentList]);

  const handleSearchChange = (e) => {
    if (!playlists) return;
    const { value } = e.target;
    setSearchValue(value);
    if (!value) setFilteredPlaylists(currentLists);
    else
      setFilteredPlaylists(
        currentLists.filter((playlist) =>
          playlist.name.toLowerCase().includes(value.toLowerCase())
        )
      );
  };

  const handlePlaylistSort = (e) => {
    const { value } = e.target;
    setSortBy(value);
  };

  useEffect(() => {
    if (isEdited) {
      const playlistsToUse =
        sortBy === "none" ? playlistsWithSpends : currentLists;
      const index = playlistsToUse.findIndex(
        (p) => p._id === selectedPlaylist._id
      );
      const editedPlaylist = {
        ...playlistsToUse[index],
        ...selectedPlaylist,
      };
      const newPlaylists = [
        ...playlistsWithSpends.slice(0, index),
        editedPlaylist,
        ...playlistsWithSpends.slice(index + 1),
      ];
      setPlaylistsWithSpends(newPlaylists);
      setFilteredPlaylists(newPlaylists);
    }
  }, [isEdited, selectedPlaylist, currentLists, sortBy, playlistsWithSpends]);

  return (
    <div className="plalists-list">
      <div className="search-input">
        <input
          type="text"
          placeholder="Search Playlists"
          value={searchValue}
          onChange={handleSearchChange}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
          <Select
            value={sortBy}
            name="sortBy"
            onChange={handlePlaylistSort}
            fullWidth
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="spend">Spend</MenuItem>
            <MenuItem value="spendPerFollower">Spend per follower</MenuItem>
            <MenuItem value="isActive">Active Campaigns</MenuItem>
            <MenuItem value="notActive">Inactive Campaigns</MenuItem>
          </Select>
        </FormControl>
      </div>
      {filteredPlaylists && (
        <div className={!loading ? "results" : "loading results"}>
          {!filteredPlaylists.length && (
            <div className="results-empty">No Playlist Found</div>
          )}
          {filteredPlaylists.length > 0 &&
            filteredPlaylists.map((item) => {
              const playlistClass =
                selectedPlaylist && selectedPlaylist._id === item._id
                  ? "results-item selected"
                  : "results-item";
              return (
                <div
                  onClick={() => setSelectedPlaylist(item)}
                  key={item._id}
                  className={playlistClass}
                >
                  <div className="name-image">
                    <div className="d-flex align-items-center">
                      <img src={item.image} alt="" />
                      <p>{item.name}</p>
                    </div>
                    <span
                      style={{
                        color: item.isActive ? "green" : "#b5b5b5",
                        marginLeft: "1rem",
                      }}
                    >
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                  </div>
                  {sortBy !== "none" && sortBy !== "notActive" && (
                    <div className="spends">
                      <div>
                        <span>Spend</span>
                        <p>{roundup(item.spend)}</p>
                      </div>
                      <div>
                        <span>SPF</span>

                        <p>{roundup(item.spendPerFollower)}</p>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default PlaylistList;
