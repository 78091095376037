import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import { format } from "date-fns";
import httpClient from "../services.httpClient";
import { Tab, Tabs } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const TABS = {
  CAMPAIGNS: "campaigns",
  PLAYLISTS: "playlists",
};

function RefreshCampaigns({ open, setIsOpen }) {
  const classes = useStyles();
  const [submissionError, setSubmissionError] = useState("");
  const [report, setReport] = useState("");
  const [latestPlaylistStreamsRefreshRun, setLatestPlaylistStreamsRefreshRun] =
    useState("");
  const [days, setDays] = useState(1);
  const [platform, setPlatform] = useState("all");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [reportVisible, setReportVisible] = useState(false);
  const [tab, setTab] = useState(TABS.CAMPAIGNS);

  const getLatestReport = () => {
    httpClient
      .get("/playlists/latest-campaign-report")
      .then((res) => setReport(res.data.report.report));
  };
  const getLatestPlaylistStreamsRefreshRun = () => {
    httpClient
      .get("/playlists/streams-refresh-run")
      .then((res) => setLatestPlaylistStreamsRefreshRun(res.data.refreshRun));
  };

  useEffect(() => {
    if (open) {
      setSubmissionError("");
      getLatestReport();
      getLatestPlaylistStreamsRefreshRun();
    }
  }, [open]);

  const handleRefreshCampaigns = () => {
    setIsRefreshing(true);
    httpClient
      .post(`/playlists/refresh-campaigns`, {
        days,
        platform,
      })
      .then(() => {
        toast.success("Campaigns refresh completed");
        setIsRefreshing(false);
        setIsOpen(false);
      })
      .catch((err) => {
        setIsRefreshing(false);
        if (err.response.data) {
          setSubmissionError(err.response.data.message);
          toast.error(err.response.data.message);
        }
      });
  };

  const handleRefreshPlaylistStreams = () => {
    setIsRefreshing(true);
    httpClient
      .post(`/playlists/refresh-streams`)
      .then(() => {
        toast.success("Playlists streams refresh started");
        setIsRefreshing(false);
        setIsOpen(false);
      })
      .catch((err) => {
        setIsRefreshing(false);
        if (err.response.data) {
          setSubmissionError(err.response.data.message);
          toast.error(err.response.data.message);
        }
      });
  };

  return (
    <div>
      <Dialog keepMounted open={open}>
        <Tabs onChange={(e, value) => setTab(value)} value={tab}>
          <Tab value={TABS.CAMPAIGNS} label="Refresh Campaigns" />
          <Tab value={TABS.PLAYLISTS} label="Refresh Playlists" />
        </Tabs>
        {tab === TABS.CAMPAIGNS && (
          <>
            <DialogTitle id="form-dialog-title">Refresh Campaigns</DialogTitle>
            <DialogContent>
              {report && (
                <>
                  <DialogContentText>
                    Last refreshed on{" "}
                    {format(new Date(report.date), "PPPPpppp")}
                  </DialogContentText>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Number of days
                    </InputLabel>
                    <Select
                      value={days}
                      name="country"
                      onChange={(e) => setDays(e.target.value)}
                      fullWidth
                    >
                      {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                        <MenuItem key={num} value={num}>
                          {num}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Platform
                    </InputLabel>
                    <Select
                      value={platform}
                      name="country"
                      onChange={(e) => setPlatform(e.target.value)}
                      fullWidth
                    >
                      {["all", "facebook", "tiktok", "snapchat"].map(
                        (platform) => (
                          <MenuItem key={platform} value={platform}>
                            {platform.toUpperCase()}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <div>
                    <br />
                    <Button onClick={() => setReportVisible(!reportVisible)}>
                      {reportVisible ? "Hide" : "View"} Detailed Report
                    </Button>
                  </div>

                  {reportVisible && (
                    <pre>{JSON.stringify(report, null, 2)}</pre>
                  )}
                </>
              )}
              <DialogContentText>
                {submissionError && (
                  <Alert severity="error">{submissionError}</Alert>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isRefreshing}
                onClick={() => setIsOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={isRefreshing}
                onClick={handleRefreshCampaigns}
                color="primary"
              >
                {isRefreshing ? "Refreshing" : "Refresh"}
              </Button>
            </DialogActions>
          </>
        )}
        {tab === TABS.PLAYLISTS && (
          <>
            <DialogTitle id="form-dialog-title">
              Refresh Playlists Streams
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Before clicking refresh button, it is important to check if
                spotify has updated the streams for today <br />
                If you proceed without checking, yesterday's streams will be set
                for today's streams and data will be incorrect
              </DialogContentText>
              {latestPlaylistStreamsRefreshRun && (
                <>
                  <DialogContentText>
                    Last refreshed on:{" "}
                    {format(
                      new Date(latestPlaylistStreamsRefreshRun.createdAt),
                      "PPPPpppp"
                    )}
                  </DialogContentText>
                  <DialogContentText>
                    Status: {latestPlaylistStreamsRefreshRun.status}
                  </DialogContentText>
                </>
              )}

              <DialogContentText>
                {submissionError && (
                  <Alert severity="error">{submissionError}</Alert>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isRefreshing}
                onClick={() => setIsOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={isRefreshing}
                onClick={handleRefreshPlaylistStreams}
                color="primary"
              >
                {isRefreshing ? "Refreshing" : "Refresh"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}

export default RefreshCampaigns;
