import React from "react";
import { formatChartDate, roundup } from "../utls/helpers";

import {
  ResponsiveContainer,
  XAxis,
  Area,
  AreaChart,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";

function DoubleCharts({ data, leftTitle, rightTitle }) {
  return (
    <div>
      <div className="title d-flex align-items-center mt-20 pt-20 pb-20 bg-white justify-content-center font-size-1 mb-10">
        <div>{data.platform.toUpperCase()}</div> : <div>{data.campaign_id}</div>{" "}
        :{/* <div>{getCampaignUser(data.campaign_id)}</div> */}
      </div>
      <div className="double-charts">
        <div className="chart-item">
          <div className="title">{leftTitle}</div>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart data={data.data}>
              <defs>
                <linearGradient id="colorSpend" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#3f51b5" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#3f51b5" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                tickLine={false}
                tickFormatter={(str) => formatChartDate(str)}
              />
              <YAxis
                domain={["dataMin - 1", "auto"]}
                dataKey="spend"
                tickCount={10}
              />
              <Area
                type="monotone"
                dataKey="spend"
                stroke="#3f51b5"
                fillOpacity={1}
                fill="url(#colorSpend)"
              />
              <Tooltip />
              <CartesianGrid opacity={0.5} vertical={false} />
            </AreaChart>
          </ResponsiveContainer>
          <div className="title">Total Spend: {roundup(data.totalSpend)}</div>
        </div>
        <div className="chart-item">
          <div className="title">{rightTitle}</div>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart data={data.data}>
              <defs>
                <linearGradient id="colorSpend" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#3f51b5" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#3f51b5" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                tickLine={false}
                tickFormatter={(str) => formatChartDate(str)}
              />
              <YAxis domain={["auto"]} dataKey="cpc" tickCount={10} />
              <Area
                type="monotone"
                dataKey="cpc"
                stroke="#3f51b5"
                fillOpacity={1}
                fill="url(#colorSpend)"
              />
              <Tooltip />
              <CartesianGrid opacity={0.5} vertical={false} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default DoubleCharts;
