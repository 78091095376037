import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import {
  ResponsiveContainer,
  XAxis,
  Area,
  AreaChart,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { SingleDatePicker } from "react-dates";
import Button from "@material-ui/core/Button";
import AddPlaylist from "./AddPlaylist";
import EditPlaylist from "./EditPlaylist";
import { format } from "date-fns";
import PlaylistList from "./PlaylistList";
import { toast } from "react-toastify";
import DeletePlaylist from "./DeletePlaylist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import RefreshCampaigns from "./RefreshCampaigns";
import httpClient from "../services.httpClient";

const formatTime = (date) => {
  return format(new Date(date), "do MMM yyyy");
};

const copyText = (text) => {
  const input = document.createElement("input");
  input.setAttribute("value", text);
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
  toast.success("Link Copied");
};
function Chart1({ selectedPlaylist, setSelectedPlaylist, dates }) {
  const today = format(new Date(), "yyyy-MM-dd");
  const [followers, setFollowers] = useState();
  const [date, setDate] = useState(moment(today));
  const [dateFocused, setDateFocused] = useState(false);
  const [addPlaylistOpen, setAddPlaylistOpen] = useState(false);
  const [editPlaylistOpen, setEditPlaylistOpen] = useState(false);
  const [deletePlaylistOpen, setDeletePlaylistOpen] = useState(false);
  const [refreshCampaignsOpen, setRefreshCampaignsOpen] = useState(false);
  const [followersPerDay, setFollowersPerDay] = useState();
  const [isEdited, setIsEdited] = useState(false);

  const loadFollowersData = useCallback((id, date) => {
    httpClient
      .get(`/playlists/${id}/followers?date=${moment(date).utc(true)}`)
      .then((res) => {
        const data = res.data.followers.map((item) => ({
          followers: item.followers,
          date: moment(item.createdAt).format("H:mm"),
        }));
        setFollowers(data);
      })
      .catch((err) => console.log(err.response));
  }, []);

  useEffect(() => {
    if (selectedPlaylist && date) {
      loadFollowersData(selectedPlaylist.spotifyId, date);
    }
  }, [selectedPlaylist, date, loadFollowersData]);

  useEffect(() => {
    httpClient
      .get(`/playlists/${selectedPlaylist.spotifyId}/followers-per-day`)
      .then((res) => {
        setFollowersPerDay(res.data.followersPerDay);
      });
  }, [selectedPlaylist]);

  useEffect(() => {
    httpClient.get("/playlists").then((res) => {
      setSelectedPlaylist(res.data.playlists[0]);
    });
  }, [setSelectedPlaylist]);

  const isOutsideRange = (day) => {
    if (selectedPlaylist)
      return (
        day.isBefore(moment(selectedPlaylist.createdAt)) ||
        day.isAfter(moment(new Date()))
      );
    else return false;
  };

  const copyPlaylistLink = () => {
    copyText("https://open.spotify.com/playlist/" + selectedPlaylist.spotifyId);
  };

  const openPlaylist = () => {
    window.open(
      "https://open.spotify.com/playlist/" + selectedPlaylist.spotifyId,
      "_blank"
    );
  };

  return (
    <div className="playlist">
      <AddPlaylist open={addPlaylistOpen} setIsOpen={setAddPlaylistOpen} />
      {selectedPlaylist && (
        <>
          <EditPlaylist
            selectedPlaylist={selectedPlaylist}
            setSelectedPlaylist={setSelectedPlaylist}
            open={editPlaylistOpen}
            setIsOpen={setEditPlaylistOpen}
            setIsEdited={setIsEdited}
          />
          <DeletePlaylist
            selectedPlaylist={selectedPlaylist}
            setSelectedPlaylist={setSelectedPlaylist}
            open={deletePlaylistOpen}
            setIsOpen={setDeletePlaylistOpen}
          />
          <RefreshCampaigns
            open={refreshCampaignsOpen}
            setIsOpen={setRefreshCampaignsOpen}
          />
        </>
      )}

      <div className="top-bar">
        <div className="date-input">
          <SingleDatePicker
            date={date}
            onDateChange={(date) => setDate(date)}
            focused={dateFocused}
            onFocusChange={({ focused }) => setDateFocused(focused)}
            id="date-picker"
            isOutsideRange={isOutsideRange}
            showDefaultInputIcon
            numberOfMonths={1}
            displayFormat="DD-MM-YYYY"
          />
        </div>
        <Button
          onClick={() => setAddPlaylistOpen(true)}
          variant="contained"
          color="primary"
        >
          Add Playlist
        </Button>
        <Button
          onClick={() => setEditPlaylistOpen(true)}
          disabled={!selectedPlaylist}
          variant="contained"
          color="primary"
        >
          Edit playlist
        </Button>
        <Button
          onClick={copyPlaylistLink}
          disabled={!selectedPlaylist}
          variant="contained"
          color="primary"
        >
          Copy Link
        </Button>
        <Button
          onClick={openPlaylist}
          disabled={!selectedPlaylist}
          variant="contained"
          color="primary"
        >
          Open Playlist
        </Button>
        <Button onClick={() => setDeletePlaylistOpen(true)} variant="contained">
          Delete Playlist
        </Button>
        <div className="refresh-button">
          <Button
            onClick={() => setRefreshCampaignsOpen(true)}
            variant="contained"
          >
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </div>
      </div>
      <div className="list-summary">
        <div className="charts-1">
          {selectedPlaylist && (
            <div className="playlist__details">
              <img src={selectedPlaylist.image} alt="" />
              <p>{selectedPlaylist.name}</p>
              <span
                style={{
                  color: selectedPlaylist.isActive ? "green" : "#b5b5b5",
                  marginLeft: "1rem",
                }}
              >
                <FontAwesomeIcon icon={faCircle} />
              </span>
            </div>
          )}
          <div className="chart-item">
            {followers && (
              <ResponsiveContainer width="100%" height={200}>
                <AreaChart data={followers}>
                  <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#3f51b5" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#3f51b5" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="date" tickLine={false} />
                  <YAxis
                    domain={["dataMin - 1", "auto"]}
                    dataKey="followers"
                    tickCount={6}
                  />
                  <Area
                    type="monotone"
                    dataKey="followers"
                    stroke="#3f51b5"
                    fillOpacity={1}
                    fill="url(#colorPv)"
                  />
                  <Tooltip />
                  <CartesianGrid opacity={0.5} vertical={false} />
                </AreaChart>
              </ResponsiveContainer>
            )}
            {selectedPlaylist && (
              <div className="playlist__date">
                {moment(date).format("MMMM Do YYYY")}
              </div>
            )}
          </div>

          <div className="chart-item">
            {followers && (
              <ResponsiveContainer width="100%" height={200}>
                <AreaChart data={followersPerDay}>
                  <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#3f51b5" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#3f51b5" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="date"
                    tickLine={false}
                    tickFormatter={(str) => formatTime(str)}
                  />
                  <YAxis
                    domain={["dataMin - 1", "auto"]}
                    dataKey="followers"
                    tickCount={6}
                  />
                  <Area
                    type="monotone"
                    dataKey="followers"
                    stroke="#3f51b5"
                    fillOpacity={1}
                    fill="url(#colorPv)"
                  />
                  <Tooltip />
                  <CartesianGrid opacity={0.5} vertical={false} />
                </AreaChart>
              </ResponsiveContainer>
            )}
            {selectedPlaylist && (
              <div className="playlist__date">Last 27 days</div>
            )}
          </div>
        </div>
        <PlaylistList
          selectedPlaylist={selectedPlaylist}
          dates={dates}
          isEdited={isEdited}
          setSelectedPlaylist={setSelectedPlaylist}
        />
      </div>
    </div>
  );
}

export default Chart1;
